import { get_pTypes_above_half, MAX_SCORE, pTestAnswerIndex, pTests } from "../utils/pTests";
import api from "./Api";

export const defaultScoreWeightings = {
    dseResult: 14,
    interestedArea: 12,
    interestedSubject: 12,
    programmeSatisfaction: 14,
    programmePurpose: 14,
    programmeContent: 14,
    jobNature: 5,
    jobValue: 4,
    jobArea: 5,
    jobSubject: 5,
    jobDescription: 5,
};


export const getScoreWeightings = () => {
    let questionOrdering = getResponseState(12);
    let weightings = defaultScoreWeightings;
    //  Rank 4-6
    if (questionOrdering.highSalary <= 6 || questionOrdering.profession <= 6) {
        weightings.dseResult = 24;
    }
    if (questionOrdering.offWorkOnTime <= 6 || questionOrdering.happiness <= 6 || questionOrdering.knowledgeApplication <= 6 || questionOrdering.lowStress <= 6 || questionOrdering.workLifeBalance <= 6) {
        weightings.interestedArea = 21;
        weightings.interestedSubject = 21;
    }
    //  Rank 1-3
    if (questionOrdering.highSalary <= 3 || questionOrdering.profession <= 3) {
        weightings.dseResult = 34;
    }
    if (questionOrdering.offWorkOnTime <= 3 || questionOrdering.happiness <= 3 || questionOrdering.knowledgeApplication <= 3 || questionOrdering.lowStress <= 3 || questionOrdering.workLifeBalance <= 3) {
        weightings.interestedArea = 30;
        weightings.interestedSubject = 30;
    }
    return weightings;
};

export const getResultMessage = () => {
    let questionOrdering = getResponseState(12);
    let messageList = [];
    //  Rank 1-6
    if (questionOrdering.highSalary <= 6) {
        messageList.push(t('您以薪金為出發點，RP的建議會加強如何成為「專業人士」為重點，而成為專業人士的條件，首先挑選出專業人士的職業，如醫生，律師，物理治療師，放射治療師，護士，教師，工程師，程序員；而駁接這些職業的JUPAS課程為：Medicine, Law, 物理治療，放射治療，Nursing, Education, Engineering, Computer Science 等。'))
    }
    if (questionOrdering.profession <= 6) {
        messageList.push(t('您以成為專業人士為出發點，RP的建議會加強如何成為「專業人士」為重點，而成為專業人士的條件，首先挑選出專業人士的職業，如醫生，律師，物理治療師，放射治療師，護士，教師，工程師，程序員；而駁接這些職業的JUPAS課程為：Medicine, Law, 物理治療，放射治療，Nursing, Education, Engineering, Computer Science 等。'))
    }
    if (questionOrdering.offWorkOnTime <= 6 || questionOrdering.happiness <= 6 || questionOrdering.lowStress <= 6 || questionOrdering.workLifeBalance <= 6) {
        messageList.push(t('您以工作中取得快樂為出發點，不希望工作是生活的全部，因此RP推算用戶若能在自己喜歡的科目和範疇中發揮所長會較適合。'))
    }
    if (questionOrdering.knowledgeApplication <= 6) {
        messageList.push(t('您很著緊大學JUPAS學科，因為希望職業生涯與JUPAS學科的知識掛鉤。'))
    }
    return messageList;
};

export const submitSurvey = async () => {
    const responseStateStr = localStorage.getItem("responseState");
    const responseState = JSON.parse(responseStateStr);
    console.log(responseState)
    let requestBody = {};
    let bodyKeyList = Object.keys(defaultScoreWeightings);  //  Index starts from 1
    bodyKeyList.push('jobImportance');
    for (const stepKey in responseState) {
        if (Object.hasOwnProperty.call(responseState, stepKey)) {
            const obj = responseState[stepKey];
            let key = {
                0: null,
                1: 'dseResult',
                2: 'interestedArea',
                3: 'interestedSubject',
                4: 'programmeSatisfaction',
                5: 'programmePurpose',
                6:'institutePreferences',
                7: 'programmeContent',
                8: 'jobNature',
                9: 'jobValue',
                10: 'jobArea',
                11: 'jobSubject',
                12: 'jobDescription',
                13: 'jobImportance',
                14: 'province',
                'subjectofinterest':'predicted_courses',
                'location':'location',
                'chinaResult':'chinaResult',
                pTest1: 'pTest1',
            }[stepKey];

            if (key === 'pTest1') {

                /**
                 * 
                 * @param {import("../utils/pTests").pTestResult} obj 
                 * @returns 
                 */
                function objToScorcs(obj) {
                    const scores = {
                        性格測試_W: 0,
                        性格測試_S: 0,
                        性格測試_C: 0,
                        性格測試_I: 0,
                        性格測試_R: 0,
                        性格測試_E: 0,
                    };

                    Object.entries(obj).map(([k, v]) => {
                        const i = pTestAnswerIndex[v];
                        if (typeof i === 'number') {
                            /** @type {import("../utils/pTests").pType} */
                            const pType = pTests[k].scores[i];

                            if (['W', 'S', 'C', 'I', 'R', 'E'].includes(pType)) {
                                scores[`性格測試_${pType}`] += 1;
                            }
                        }
                    })
                    // console.log({ scores });
                    return scores;
                }

                const scores = objToScorcs(obj);
                const pTypes_above_half = get_pTypes_above_half(scores, MAX_SCORE);

                requestBody.pTest1 = {
                    pTestResult: obj,
                    scores,
                    MAX_SCORE,
                    // pTypes_above_half,
                };

            } else if (key) {
                requestBody[key] = obj;
            }
        }
    }
    /* for (let i = 1; i < responseState.length; i++) {
        let key = bodyKeyList[i-1]; //  Index starts from 0
        requestBody[key] = responseState[i];
    } */
    const response = await api.post(`/survey/result`, requestBody, { timeout: 20000 });
    return response.data;
};

export const submitSurveyLow = async () => {
    const responseStateStr = localStorage.getItem("responseState");
    const responseState = JSON.parse(responseStateStr);
    let requestBody = {};
    let bodyKeyList = Object.keys(defaultScoreWeightings);  //  Index starts from 1
    bodyKeyList.push('jobImportance');
    for (const stepKey in responseState) {
        if (Object.hasOwnProperty.call(responseState, stepKey)) {
            const obj = responseState[stepKey];
            let key = {
                0: null,
                14: 'hdInstituteLvl',
                15: 'hdInstitute',
                16: 'hdInterest',
                17:'hdFuturePlan',
            }[stepKey];
             if (key) {
                  if(key==='hdFuturePlan'){
                      requestBody[key] = Object.keys(obj)[0];
                  }else{
                    requestBody[key] = obj;
                  }                    
                }
        }
    }
    const response = await api.post(`/survey/hd_result`, requestBody, { timeout: 20000 });
    return response.data;
};

export const submitSurveychoose = async () => {
    const responseStateStr = localStorage.getItem("responseState");
    const responseState = JSON.parse(responseStateStr);
    let requestBody = {};
    let bodyKeyList = Object.keys(defaultScoreWeightings);  //  Index starts from 1
    bodyKeyList.push('jobImportance');
    for (const stepKey in responseState) {
        if (Object.hasOwnProperty.call(responseState, stepKey)) {
            const obj = responseState[stepKey];
            let key = {
                 0: null,
                17:'hdFuturePlan',
                18:'hdWorkPlan'
            }[stepKey];
            if (key) {
                  requestBody[key] = Object.keys(obj)[0];                  
             }
        }  
    }
    const response = await api.post(`/survey/hd_result`, requestBody, { timeout: 20000 });
    return response.data;
};

export const submitCareerChoose = async () => {
    const responseStateStr = localStorage.getItem("responseState");
    const responseState = JSON.parse(responseStateStr);
    // console.log(responseState);
    let setPostResponseState={}
     setPostResponseState = Object.assign(setPostResponseState,responseState);
     let career1Data =  setPostResponseState.career1;
     let career2Data =  setPostResponseState.career2;
     let postData = [];
      postData = career1Data;
      postData.map((item)=>{
        for (let subitem in career2Data) {
            if('career'+item.id==subitem){
                item['order']=career2Data[subitem];
                return item;
            }
          };
      })
      if(postData.length){
         const response = await api.post(`/career/result`, postData, { timeout: 20000 });
         return response.data;
      }
};

export const personalityTest = async(saveKey)=>{
    const responseStateStr = localStorage.getItem("responseState");
    const responseState = JSON.parse(responseStateStr);
    let postData=[];
     let mergeData = {};
     if(!saveKey.length||JSON.stringify(responseState)==={}) return
       saveKey.map((saveKeyItem)=>{
         if (Object.hasOwnProperty.call(responseState,saveKeyItem)) {
               mergeData = {...mergeData,...responseState[saveKeyItem]}
           }
       });
      for(let mergeDataKey in mergeData){
          postData.push(mergeData[mergeDataKey])
       }
    if(postData.length){
        const response = await api.post(`/personality/test`, postData, { timeout: 20000 });
        // console.log(response.data)  
        return response.data;
     }
}

export const recommendationId = async (record_id,answer,number) => {
       let postData={
         record_id,
         number,
         answer
       }
      const response = await api.post(`/career/recommendation`, postData, { timeout: 20000 });
      return response.data;
    
};

export const getPersonalityQuestions = async (page) => {
    const response = await api.get(`/personality/questions/${page}`);
    return response.data;
};

export const getRecommendation = async (setNo) => {
    const response = await api.get(`/personality/recommendation/${setNo}`);
    return response.data;
};

export const getPersonalityExplanation = async () => {
    const response = await api.get(`/personality/explanation`);
    return response.data;
};

export const getSurveyHistoryList = async () => {
    const response = await api.get(`/survey/history`);
    // console.log(response.data)
    return response.data;
};

export const getCareerHistoryList = async () => {
    const response = await api.get(`/career/history`);
    return response.data;
};
 
export const getCharacterHistoryList = async () => {
    const response = await api.get(`/personality/history`);
    return response.data;
};
export const getSalaryHistoryList = async () => {
    const response = await api.get(`/salary/history`);
    return response.data;
};


export const getSurveyHistory = async (recordId) => {
    const response = await api.get(`/survey/history/${recordId}`);
    return response.data;
};

export const getCareerHistory = async (recordId) => {
    const response = await api.get(`/career/history/${recordId}`);
    return response.data;
};
export const getCharacterHistory = async (recordId) => {
    const response = await api.get(`/personality/history/${recordId}`);
    return response.data;
};
export const getSalaryHistory = async (recordId) => {
    const response = await api.get(`/salary/history/${recordId}`);
    return response.data;
};
export const salaryReaminingTrial = async () => {
    const response = await api.get('/salary/reamining-trial');
    return response.data;
};
export const getResponseState = (step) => {
    let responseState = localStorage.getItem("responseState");
    if (responseState !== null) {
        responseState = JSON.parse(responseState);
        responseState = { ...responseState };
    } else {
        return {};
    }
    return responseState[step] ?? {};
};

export const saveResponseState = (step, responseValue) => {
    let responseState = localStorage.getItem("responseState");
    if (responseState !== null) {
        responseState = JSON.parse(responseState);
        responseState = { ...responseState };
    } else {
        responseState = {};
    }
    responseState[step] = responseValue;
    localStorage.setItem("responseState", JSON.stringify(responseState));
};

export const resetResponseState = () => {
    localStorage.setItem("responseState", '{}');
};

export const getSalaryList = async()=>{
    const response = await api.get(`/salary/list`);
    // console.log(response.data)
    return response.data;
}
export const getCareerList = async()=>{
    const response = await api.get(`/career/list`);
    // console.log(response.data)
    return response.data;
}

export const getCurrentCategory = async (job_title) => {
    let postData={
        job_title,
    }
   const response = await api.post(`/career/job-ad`, postData, { timeout: 20000 });
   return response.data;
};

export const getJupasProgrammes = async () => {
    const response = await api.get(`/survey/jupas_programmes`);
    // console.log(response.data)
    return response.data;
};

export const timerPromotion = async (setNo) => {
    const response = await api.get('/timer/promotion');
    return response.data;
};
export const bannerButton = async (setNo) => {
    const response = await api.get('/banner/button');
    return response.data;
};

export const careerConvertPremium = async (record_id) => {
   const response = await api.post('/career/convert_premium', {record_id}, { timeout: 20000 });
   return response.data;
};
export const personalityConvertPremium = async (record_id) => {
    const response = await api.post('/personality/convert_premium', {record_id}, { timeout: 20000 });
    return response.data;
 };
 export const surveyConvertPremium = async (record_id) => {
    const response = await api.post('/survey/convert_premium', {record_id}, { timeout: 20000 });
    return response.data;
 };
 export const postSalaryResult = async (salaryresult) => {
    const response = await api.post('/salary/result',salaryresult, { timeout: 20000 });
    return response.data;
 };
 
 export const salaryDummy = async () => {
    const response = await api.get('/salary/dummy');
    return response.data;
};